<template>
    <div>
      <section class="hero is-primary">
        <div class="hero-body">
          <p class="title">
            Users
          </p>
          <p class="subtitle">
            Manage Users
          </p>
        </div>
        <div class="hero-foot">
          <nav class="tabs">
            <div class="container">
              <ul>
                <li>
                  <b-input class="is-primary"
                    placeholder="Search..."
                    type="search"
                    v-model="searchQuery" />
                </li>
                <!-- TODO: sort -->
                <li v-if="false">
                  <b-button
                    label="Sort"
                    type="is-primary"
                    @click="$buefy.notification.open({ message: 'Sorting is disabled.\nWaiting for bulma to fix dropdowns.', type: 'is-warning', duration: 5000})"/>
                </li>
                <li><b-button v-if="hasPermission('users.create')" type="is-primary" @click="imprt">Import User</b-button></li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
      <div class="container mt-3">
        <div class="columns is-multiline" v-show="users">
            <div class="column is-3" v-for="(user, i) in computedUsers" v-bind:key="i">
              <div class="card">
                <div class="card-header">
                  <p class="card-header-title">{{ user.username }}#{{ user.discriminator}}</p>
                  <div class="card-header-icon buttons">
                    <b-button v-if="hasPermission('users.update')" type="is-info" @click="edit(i)">
                      <b-icon
                        icon="pencil"
                        size="is-small" />
                    </b-button>
                    <b-button v-if="hasPermission('users.delete')" type="is-info" @click="del(i)">
                      <b-icon
                        icon="trash-can"
                        size="is-small" />
                    </b-button>
                  </div>
                </div>
                <div class="card-content">
                  <div class="subtitle">
                    <ul>
                      <li v-if="user.settings.blacklisted"><b><span class="has-text-danger">User is Blacklisted</span></b></li>
                      <li><b>Tickets</b>: {{ user.settings.tickets }}</li>
                      <li><b>Level</b>: {{ user.settings.level }}</li>
                      <li><b>XP</b>: {{ user.settings.currentXp }} ({{ user.settings.totalXp }})</li>
                      <li><b>Permissions</b>: <span @click="showPerms(i)" style="cursor:pointer;">{{ user.settings.permissions.length }}</span></li>
                      <li v-if="user.settings.vcTime"><b>VC Time</b>: {{ user.settings.vcTime }} seconds</li>
                      <li v-if="user.settings.clappyImageUrl"><b>Clappy</b>: <a :href="user.settings.clappyImageUrl">Click me!</a></li>
                      <li v-if="user.settings.msgCount"><b>Messages</b>: {{ user.settings.msgCount }}</li>
                    </ul>
                  </div>
                </div>
                <div class="card-image">
                  <b-image class="image is-4by3" :src="`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}`" />
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import { hasPermission } from '@/utils/permissions'
import { getUsers } from '@/api/users'
import ImportModal from './modals/Import'
import EditModal from './modals/Edit'
import DeleteModal from './modals/Delete'

export default {
  data: () => ({
    users: [],
    searchQuery: ''
  }),
  computed: {
    computedUsers () {
      if (this.searchQuery) { return this.users.filter(u => `${u.username.toLowerCase()}#${u.discriminator}`.includes(this.searchQuery)) }
      return this.users
    }
  },
  methods: {
    hasPermission (permission) {
      return hasPermission(permission)
    },
    async fetchUsers () {
      this.users = await getUsers()
    },
    showPerms (idx) {
      this.$buefy.dialog.alert({
        title: `${this.computedUsers[idx].username}'s Permissions`,
        message: `<ul><li>${this.computedUsers[idx].settings.permissions.join('</li><li>')}</li></ul>`,
        confirmText: 'Ok'
      })
    },
    imprt () {
      this.$buefy.modal.open({
        parent: this,
        component: ImportModal,
        hasModalCard: true,
        trapFocus: true,
        events: {
          submit: (user) => {
            this.fetchUsers()
          }
        }
      })
    },
    edit (idx) {
      this.$buefy.modal.open({
        parent: this,
        component: EditModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          userData: this.computedUsers[idx],
          idx
        },
        events: {
          submit: (idx, user) => {
            this.computedUsers[idx].settings = user
          }
        }
      })
    },
    del (idx) {
      this.$buefy.modal.open({
        parent: this,
        component: DeleteModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          userData: this.computedUsers[idx]
        },
        events: {
          submit: (id) => {
            this.users.splice(this.users.findIndex(u => u.id === id), 1)
          }
        }
      })
    }
  },
  mounted () {
    const load = this.$buefy.loading.open()
    this.fetchUsers().then(() => load.close())
  }
}
</script>
