<template>
    <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">EDIT '{{ userData.username }}'</p>
    </header>
    <section class="modal-card-body">
      <div class="columns">
        <b-field class="column" label="Tickets" expanded>
          <b-numberinput min="0" v-model="form.tickets" placeholder="5000" step="1" block controls-alignment="right" controls-position="compact"></b-numberinput>
        </b-field>
        <b-field class="column" label="Permissions">
          <multiselect
            v-model="form.permissions"
            :options="permissions"
            multiple
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick Permissions"
            :preselect-first="true">
            <template #selection="{values, isOpen}">
              <span
                class="multiselect__single"
                v-if="values.length && !isOpen">
                {{ values.length }} permissions selected
              </span>
              </template>
          </multiselect>
        </b-field>
      </div>
      <div class="columns">
        <b-field class="column" label="Level">
          <b-numberinput min="0" v-model="form.level" placeholder="5000" step="1" block controls-alignment="right" controls-position="compact"></b-numberinput>
        </b-field>
        <b-field class="column" label="Total XP">
          <b-numberinput min="0" v-model="form.totalXp" placeholder="5000" step="1" block controls-alignment="right" controls-position="compact"></b-numberinput>
        </b-field>
        <b-field class="column" label="Blacklisted">
          <b-switch v-model="form.blacklisted" type="is-danger" />
        </b-field>
      </div>

      <div class="columns mt-5">
        <div class="column is-4 is-offset-8 buttons">
          <b-button
            label="Cancel"
            type="is-primary"
            @click="$emit('close')" />
          <b-button
            label="Submit"
            type="is-info"
            @click="submit" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { updateUser } from '@/api/users'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  props: {
    userData: Object,
    idx: Number
  },
  data: () => ({
    form: {},
    permissions: ['users.list', 'users.delete', 'users.update']
  }),
  methods: {
    async submit () {
      try {
        await updateUser(this.userData.id, this.form)
        this.$buefy.notification.open({
          message: `User <b>${this.userData.username}</b> updated successfully!`,
          type: 'is-success'
        })
        this.$emit('close')
        this.$emit('submit', this.idx, this.form)
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    }
  },
  mounted () {
    this.form = { ...this.userData.settings }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@/sass/darkmode.scss";

.multiselect .multiselect__tags,
.multiselect .multiselect__tags span,
.multiselect .multiselect__tags input {
  background-color: $ddarker;
  border-color: $ddarker;
  color: $text;
}

.multiselect .multiselect__content-wrapper {
  background-color: $ddark;
  border-color: $ddark;
  color: $text
}

.multiselect .multiselect__option--selected,
.multiselect .multiselect__option--highlight,
.multiselect .multiselect__option--highlight::after {
  background-color: $dblue;
  border-block: $dblue;
  color: $text;
}

</style>
