<template>
    <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title"></p>
    </header>
    <section class="modal-card-body">
      <div class="columns">
        <b-field class="column" label="User" expanded>
          <b-autocomplete
            required
            v-model="userQuery"
            :data="filteredUsers"
            clearable
            open-on-focus
            field="tag"
            @select="option => form.user = option">
            <template #empty>No results found</template>
          </b-autocomplete>
        </b-field>

        <b-field class="column" label="Tickets" expanded>
          <b-numberinput min="0" v-model="form.tickets" placeholder="5000" step="1" block controls-alignment="right" controls-position="compact"></b-numberinput>
        </b-field>
        <b-field class="column" label="Permissions">
          <multiselect
            v-model="form.permissions"
            :options="permissions"
            multiple
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick Permissions"
            :preselect-first="true">
            <template #selection="{values, isOpen}">
              <span
                class="multiselect__single"
                v-if="values.length && !isOpen">
                {{ values.length }} permissions selected
              </span>
            </template>
          </multiselect>
        </b-field>
      </div>
      <div class="columns">
        <b-field class="column" label="Level">
          <b-numberinput min="0" v-model="form.level" placeholder="5000" step="1" block controls-alignment="right" controls-position="compact"></b-numberinput>
        </b-field>
        <b-field class="column" label="Total XP">
          <b-numberinput min="0" v-model="form.totalXp" placeholder="5000" step="1" block controls-alignment="right" controls-position="compact"></b-numberinput>
        </b-field>
        <b-field class="column" label="Blacklisted">
          <b-switch v-model="form.blacklisted" type="is-danger" />
        </b-field>
      </div>

      <div class="columns mt-5">
        <div class="column is-4 is-offset-8 buttons">
            <b-button
              label="Cancel"
              type="is-primary"
              @click="$emit('close')" />
            <b-button
              label="Submit"
              type="is-info"
              @click="submit" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { createUser } from '@/api/users'
import { getMembers } from '@/api/guild'
import { getPermissions } from '@/api/permissions'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  data: () => ({
    userQuery: '',
    form: {},
    permissions: [],
    permissionsFetched: false,
    users: [],
    usersFetched: false
  }),
  computed: {
    filteredUsers () {
      return this.users.filter(option => {
        return option.tag.toLowerCase().indexOf(this.userQuery.toLowerCase()) >= 0
      })
    }
  },
  methods: {
    async fetchPermissions () {
      const permissions = await getPermissions()
      this.permissions = permissions
      this.permissionsFetched = true
    },
    async fetchUsers () {
      const users = await getMembers()
      this.users = users
      this.usersFetched = true
    },
    async submit () {
      try {
        await createUser({ ...this.form, ...{ id: this.form.user.id } })
        this.$buefy.notification.open({
          message: `User <b>${this.form.user.tag}</b> imported successfully!`,
          type: 'is-success'
        })
        this.$emit('close')
        this.$emit('submit', this.form)
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    }
  },
  mounted () {
    this.fetchPermissions()
    this.fetchUsers()
  }
}
</script>
